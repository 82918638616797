import classNames from 'classnames';
import { useState } from 'react';
import styles from './contacts.module.scss';
import OttoMap from './map/Map';
import Icon from './../../elements/icon/Icon';
import './social.scss';

const Contacts = (props) => {
  return (
    <footer style={{backgroundColor: `${props.color}`}} id='contacts' className={classNames(styles.wrapper)}>
      <div className={styles.mapContainer}>
        <OttoMap />
      </div>
      <div className={styles.contactsContainer}>
        <h4
          data-aos='fade-down'
          data-aos-duration='1100'
          className={styles.title}
        >
          Наші контакти:
        </h4>
        <div className={styles.contacts}>
          <div
            data-aos='fade-down'
            data-aos-duration='1100'
            className={styles.contactTitles}
          >
            <span className={styles.contactTitle}>Телефон</span>
            <span className={styles.contactTitle}>E-mail</span>
            <span className={styles.contactTitle}>Наша адреса</span>
            <span className={styles.contactTitle}>Соцмережі</span>
          </div>
          <div
            data-aos='fade-down'
            data-aos-duration='1100'
            className={styles.contactInfos}
          >
            <span className={styles.contactInfo}>+380994340888</span>
            <span className={styles.contactInfo}>info@ottohans.com.ua</span>
            <span className={styles.contactInfo}>
              Івано-Франківськ, вул. Гнатюка 22
            </span>
            <div
              data-aos='fade-down'
              data-aos-duration='1100'
              className={classNames('socialMedia', styles.socialMedia)}
            >
              <div className={styles.social}>
                <a
                  className={styles.link}
                  href='https://www.facebook.com/ottohans.sprachstudio/'
                >
                  <Icon iconName='facebook' />
                </a>
              </div>
              <div className={styles.social}>
                <a
                  className={styles.link}
                  href='https://www.instagram.com/ottohans_/'
                >
                  <Icon iconName='instagram' />
                </a>
              </div>
              <div className={styles.social}>
                <a className={styles.link} href='https://t.me/ottohans_school'>
                  <Icon iconName='telegram' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Contacts;
