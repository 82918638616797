import React from 'react';
import styles from './format.module.scss';
import classNames from 'classnames';
import { WaveDown, WaveUp } from '../../../../components/about-us/waves';
import Icon from '../../../../elements/icon/Icon';
import Heading from '../../../../elements/heading/Heading';
import './format-swiper.scss';

// Import Swiper SCSS
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/autoplay';

// Swiper modules
import { Navigation, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const data = [
  {
    name: 'Стандарт',
    icon: 'smiling-emoji',
    services: [
      'Доступ до платформи 1,5 місяці',
      'Матеріали у Telegram-чаті',
      'Доступ до спільноти у Telegram',
      'Сертифікат про проходження курсу',
      'Додаткові граматичні матеріали',
      'Перевірка письмових робіт',
      'Онлайн-сесії',
      'Індивідуальний фідбек від викладача',
      'Доступ до додаткових ресурсів',
    ],
    price: '690',
    servicesAvailable(index) {
      return index <= 4 ? 'tick' : 'no';
    },
  },
  {
    name: 'Куратор',
    icon: 'smiling-emoji-2',
    services: [
      'Доступ до платформи 1,5 місяці',
      'Матеріали у Telegram-чаті',
      'Доступ до спільноти у Telegram',
      'Сертифікат про проходження курсу',
      'Додаткові граматичні матеріали',
      'Перевірка письмових робіт (куратором)',
      '3 сесії по 1.5 години у невеликій групі',
      'Індивідуальний фідбек від викладача',
      'Доступ до додаткових ресурсів',
    ],
    price: '2990',
    servicesAvailable(index) {
      return index <= 6 ? 'tick' : 'no';
    },
  },
  {
    name: 'Преміум',
    icon: 'heart-eyes-emoji',
    services: [
      'Доступ до платформи 3 місяці',
      'Матеріали у Telegram-чаті',
      'Доступ до спільноти у Telegram',
      'Сертифікат про проходження курсу',
      'Додаткові граматичні матеріали (+ індивідуальні завдання)',
      'Перевірка письмових робіт (викладачем)',
      '5 індивідуальних сесій по 1 годині',
      'Індивідуальний фідбек від викладача',
      'Доступ до додаткових ресурсів (+ екслюзивні матеріали)',
    ],
    price: '6990',
    servicesAvailable(index) {
      return index <= 9 ? 'tick' : 'no';
    },
  },
];

const Format = () => {
  return (
    <div className={classNames('container')}>
      <div className={classNames(styles.format)}>
        <div className={styles.titleContainer}>
          <Heading name='Формат участі у програмі підготовки до DTZ B1' />
        </div>
        <div className={styles.contentContainer}>
          <Swiper
            modules={[Navigation, A11y]}
            slidesPerView={'auto'}
            spaceBetween={20}
            autoplay
            speed={700}
            navigation
            className='myFormatSwiper'
          >
            {data.map((card) => (
              <SwiperSlide>
                <div className={styles.card}>
                  <div className={styles.mainInfo}>
                    <div className={styles.iconContainer}>
                      <Icon iconName='pencil' />
                    </div>
                    <div className={styles.cardTitleContainer}>
                      <div className={styles.secondIconContainer}>
                        <Icon iconName={card.icon} />
                      </div>
                      <h2 className={styles.cardTitle}>{card.name}</h2>
                    </div>
                    <div className={styles.divider}></div>
                    <ul className={styles.cardContentContainer}>
                      {card.services.map((service, index) => (
                        <li
                          className={
                            card.name === 'Стандарт' && index > 4
                              ? styles.listItemDisable
                              : card.name === 'Куратор' && index > 6
                              ? styles.listItemDisable
                              : styles.listItem
                          }
                          key={`${card.name}-${index}`}
                        >
                          <div className={styles.listIcon}>
                            <Icon
                              iconName={
                                card.name === 'Стандарт'
                                  ? card.servicesAvailable(index)
                                  : card.name === 'Куратор'
                                  ? card.servicesAvailable(index)
                                  : card.servicesAvailable(index)
                              }
                            />
                          </div>
                          <p className={styles.listItemText}>{service}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className={styles.priceContainer}>
                    <div className={styles.price}>{card.price}грн</div>
                    <a
                      href='https://t.me/ottohans_school_bot?start=6745f14641d917f296009832'
                      className={styles.button}
                    >
                      <span>Обрати план</span>
                    </a>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Format;
