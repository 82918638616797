import React from 'react';
import styles from './for.module.scss';
import Heading from '../../../../elements/heading/Heading';
import Icon from '../../../../elements/icon/Icon';
import classNames from 'classnames';
import { WaveUp } from '../../../../components/about-us/waves';

const infos = [
  {
    number: '1',
    text: 'Маєш вже А2 або В1',
  },
  {
    number: '2',
    text: 'Склав іспит, але не успішно',
  },
  {
    number: '3',
    text: 'Якщо твоя ціль - В1',
  },
];

const For = () => {
  return (
    <div className='container'>
      <div className={classNames('wrapper', styles.for)}>
        <div className={styles.titleContainer}>
          <div className={styles.iconContainer}>
            <Icon iconName='lightning' />
          </div>
          <Heading name='Кому підійде курс?' />
        </div>
        <div className={styles.infos}>
          {infos.map((info) => (
            <div key={info.number} className={styles.infoContainer}>
              <div className={styles.number}>{info.number}</div>
              <p className={styles.text}>{info.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default For;
