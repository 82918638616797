import React from 'react';
import Icon from '../../../../elements/icon/Icon';
import classNames from 'classnames';
import styles from './teacher.module.scss';
import Heading from '../../../../elements/heading/Heading';
import khrystia from './../../../../assets/img/dtz/khrystia.png';

const data = [
  {
    icon: 'otto',
    text: 'Викладач і репетитор німецької мови для дітей та дорослих у школі «Otto Hans».',
  },
  {
    icon: 'star',
    text: 'Має понад 10 років досвіду викладання німецької мови на різних рівнях.',
  },
  {
    icon: 'diagram',
    text: 'Пройшла курс підготовки С1 від Goethe-Institut і отримала міжнародний сертифікат.',
  },
  {
    icon: 'pen',
    text: 'Пройшла серію курсів DLL (Deutsch Lehren Lernen) з підготовки уроків, використання технологій та методів викладання.',
  },
  {
    icon: 'uni',
    text: 'Стажувалась у Інституті міжнародної комунікації в Єні, Німеччина (Єнський університет ім. Фрідріха Шиллера).',
  },
  {
    icon: 'tv',
    text: 'Активно використовує сучасні медіа та інноваційні методики на заняттях.',
  },
  {
    icon: 'bulb',
    text: 'Основний акцент робить на мотивації та ентузіазмі учнів.',
  },
];

const Teacher = () => {
  return (
    <div className={classNames('container', styles.teacher)}>
      <div className={classNames('wrapper')}>
        <div className={styles.titleContainer}>
          <Heading name='Викладач' />
          <div className={styles.iconContainer}>
            <Icon iconName='heart-2' />
          </div>
        </div>
        <div className={styles.contentsContainer}>
          <div className={styles.imgContainer}>
            <img src={khrystia} alt='Khrystia' />
          </div>
          <div className={styles.contentContainer}>
            <ul className={styles.list}>
                          <h3 className={styles.cardTitle}>ХРИСТИНА</h3>
              {data.map((item, index) => (
                <li key={`li-${index}`} className={styles.listItem}>
                  <div className={styles.iconTwoContainer}>
                    <Icon iconName={item.icon} />
                  </div>
                  <p className={styles.itemText}>{item.text}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teacher;
