import classNames from 'classnames';
import React from 'react';
import Icon from '../../../../elements/icon/Icon';
import styles from './reviews.module.scss';
import Heading from '../../../../elements/heading/Heading';

import commentOne from './../../../../assets/img/comments/dtz/1.jpg';
import commentTwo from './../../../../assets/img/comments/dtz/2.jpg';
import commentThree from './../../../../assets/img/comments/dtz/3.jpg';
import commentFour from './../../../../assets/img/comments/dtz/4.jpg';
import commentFive from './../../../../assets/img/comments/dtz/2.png';

const Reviews = () => {
  return (
    <div className={classNames('container', styles.reviews)}>
      <div className={styles.iconSmile}>
        <Icon iconName='smiley-1' />
      </div>
      <div className={styles.iconPencil}>
        <Icon iconName='pencil' />
      </div>
      <div className={styles.iconRuler}>
        <Icon iconName='ruler' />
      </div>
      <div className={classNames(styles.comment, styles.commentOne)}>
        <div data-aos='flip-right' data-aos-duration='1500'>
          <img src={commentOne} alt='Comment one' />
        </div>
      </div>
      <div className={classNames(styles.comment, styles.commentTwo)}>
        <div data-aos='flip-right' data-aos-duration='1500'>
          <img src={commentTwo} alt='Comment two' />
        </div>
      </div>
      <div className={classNames(styles.comment, styles.commentThree)}>
        <div data-aos='flip-right' data-aos-duration='1500'>
          <img src={commentThree} alt='Comment three' />
        </div>
      </div>
      <div className={classNames(styles.comment, styles.commentFour)}>
        <div data-aos='flip-left' data-aos-duration='1500'>
          <img src={commentFour} alt='Comment four' />
        </div>
      </div>
      <div className={classNames(styles.comment, styles.commentFive)}>
        <div data-aos='flip-left' data-aos-duration='1500'>
          <img src={commentFive} alt='Comment five' />
        </div>
      </div>
      <div className={classNames('wrapper')}>
        <div className={styles.titleContainer}>
          <Heading name='Що кажуть учні Христини' />
        </div>
      </div>
    </div>
  );
};

export default Reviews;
