import classNames from 'classnames';
import Heading from '../../elements/heading/Heading';
import styles from './learn-with.module.scss';


// * Images
import heart from './../../assets/img/heart.png'
import arrow from './../../assets/img/arrow.png';
import book from './../../assets/img/book.png';
import tv from './../../assets/img/tv.png';
import ja from './../../assets/img/ja.png';
import phone from './../../assets/img/phone.png';
import pencil from './../../assets/img/pencil.png'



const LearnWith = (props) => {
  const cards = [
    {
      id: 1,
      text: 'переїхати у німецькомовну/англомовну країну',
      image: heart,
      alt: 'Heart',
      imgExtra: arrow,
      altExtra: 'Arrow',
      width: '63px',
    },
    {
      id: 2,
      text: 'читати вивіски, реклами, газети та літературу мовою оригіналу',
      image: book,
      alt: 'A book',
      width: '64px',
    },
    {
      id: 3,
      text: 'розуміти пісні, фільми, жарти, а головне носія мови',
      image: tv,
      alt: 'A TV-set',
      width: '83px',
    },
    {
      id: 4,
      text: 'успішно скласти іспит на потрібний рівень мови',
      image: pencil,
      alt: 'A pencil',
      width: '68px',
    },
    {
      id: 5,
      text: 'впевнено розмовляти на улюблені актуальні теми',
      image: ja,
      alt: 'Image with ja writing',
      width: '94px',
    },
    {
      id: 6,
      text: 'вести приватне та офіційне листування',
      image: phone,
      alt: 'A phone with a heart',
      width: '85px',
    },
  ];

  return (
    <div className={classNames('wrapper', styles.wrapper)}>
      <div
        data-aos='fade-right'
        data-aos-duration='500'
        className={styles.heading}
      >
        <Heading name='З нами ти зможеш:' />
      </div>
      <div className={styles.cards}>
        <div className={styles.extraImgContainer}>
          <img src={cards[0].imgExtra} alt={cards[0].altExtra} />
        </div>
        {cards.map((card) => (
          <div
            className={classNames(styles.card, `card-${card.id}`)}
          >
            <div className={styles.imgContainer} style={{ width: card.width }}>
              <img src={card.image} alt={card.alt} />
            </div>
            <div className={styles.text}>{card.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LearnWith;
