import Icon from '../../elements/icon/Icon';

import styles from './bot.module.scss';
import './bot.scss';
import classNames from 'classnames';

const Bot = (props) => {
  return (
    <a
      className={classNames('bot', styles.bot)}
      href={props.link}
      style={{ bottom: `${props.bottom}` }}
    >
      <Icon iconName='bot' />
    </a>
  );
};

export default Bot;
