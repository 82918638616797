import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Link as ExternalLink } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import styles from './dtz.module.scss';
import classNames from 'classnames';
import Icon from './../../elements/icon/Icon';
import './icons.scss';
import Banner from './components/banner/Banner';
import For from './components/for/For';
import CourseProgram from './components/course-program/CourseProgram';
import Special from './components/special/Special';
import Format from './components/format/Format';
import Book from './components/book/Book';
import Teacher from './components/teacher/Teacher';
import Video from './components/video/Video';
import Reviews from './components/reviews/Reviews';
import Contacts from './../../components/contacts/Contacts';
import ModalUp from '../../components/modal-up/ModalUp';
import Phone from '../../components/phone/Phone';
import Bot from '../../components/bot/Bot';
// import Sertificates from './components/sertificates/Sertificates';

const DTZ = () => {
  const telegram = <Icon iconName='facebook' />;

  const button = {
    text: 'Пробне заняття',
  };
  const [isOn, setIsOn] = useState(false);
  const [click, setClick] = useState(false);

  return (
    <div className='container'>
      <ModalUp bottom='90px' />
      <Bot
        link='https://t.me/ottohans_school_bot?start=6745f14641d917f296009832'
        bottom='40px'
      />
      <div className={isOn ? styles.wrapperOn : styles.wrapper}>
        <div id='header' className={styles.headerWrapper}>
          <ExternalLink to='/'>
            <div className={styles.logoContainer}>
              <div className={styles.logo}>
                <Icon iconName='logo' />
              </div>
              <div className={styles.logoWriting}>
                <Icon iconName='logo-writing' />
              </div>
            </div>
          </ExternalLink>
          <div className={styles.navbar}>
            <Navbar
              navClassName={classNames(
                isOn ? 'nav-links-mobile-on' : 'nav-links',
                'nav-links-mobile'
              )}
              navOnClick={() => setIsOn(false)}
              burgerOnClick={() => setIsOn(!isOn)}
              burgerLineClass={!isOn ? styles.burgerLine : styles.burgerOpen}
            />
          </div>
          <div className={classNames('headerSocial', styles.socialMedia)}>
            <div className={styles.circle}>
              <a
                className={styles.link}
                href='https://www.facebook.com/ottohans.sprachstudio/'
              >
                <Icon iconName='facebook' />
              </a>
            </div>
            <div className={styles.circle}>
              <a
                className={styles.link}
                href='https://www.instagram.com/ottohans_/'
              >
                <Icon iconName='instagram' />
              </a>
            </div>
            <div className={styles.circle}>
              <a className={styles.link} href='https://t.me/ottohans_school'>
                <Icon iconName='telegram' />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Banner isOn={isOn} />
      <For />
      <Special />
      <CourseProgram />
      <Format />
      <Book />
      <Teacher />
      <Video />
      <Reviews />
      <Contacts color='#fff' />
      {/* <Sertificates /> */}
    </div>
  );
};

export default DTZ;
