import React from 'react';
import styles from './video.module.scss';
import classNames from 'classnames';
import Icon from '../../../../elements/icon/Icon';
import Heading from '../../../../elements/heading/Heading';

const Video = () => {
  return (
    <div className={classNames('container', styles.video)}>
      <div className={styles.redDot}></div>
      <div className={styles.blueDot}></div>
      <div className={styles.yellowDot}></div>
      <div className={styles.iconSmile}>
        <Icon iconName='smiley-3' />
      </div>
      <div className={styles.iconSmileTwo}>
        <Icon iconName='smiley-2' />
      </div>
      <div className={styles.iconLightning}>
        <Icon iconName='lightning' />
      </div>
      <div className={styles.iconHeart}>
        <Icon iconName='heart' />
      </div>

      <div className={styles.titleContainer}>
        <Heading name='Відео з викладачем' />
      </div>
      <iframe
        className={styles.videoContainer}
        src='https://www.youtube.com/embed/qMKzMWfA0S0?si=OvHDH37qIJwPPc3s'
        title='YouTube video player'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default Video;
