import React, { useState } from 'react';
import styles from './banner.module.scss';
import Icon from '../../../../elements/icon/Icon';
import { ButtonTwo } from '../../../../elements/button/Button';
import adults from './../../../../assets/img/dtz/adults.png';
import classNames from 'classnames';

const Banner = (props) => {
  const button = {
    text: 'Записатися на курс В1',
  };
  const [isOn, setIsOn] = useState(false);
  const [click, setClick] = useState(false);
  return (
    <div
      className={classNames(
        props.isOn ? styles.bannerOn : styles.banner,
        'wrapper'
      )}
    >
      <div className={styles.dotYellow}></div>
      <div className={styles.imageContainer}>
        <div className={styles.iconPencilContainer}>
          <Icon iconName='pencil' />
        </div>
        <img src={adults} alt='Adults' />
      </div>
      <div className={styles.textBlock}>
        <div className={styles.dotRed}></div>
        <div className={styles.dotBlue}></div>
        <h1 className={styles.pageTitle}>DTZ B1</h1>
        <h4 className={styles.titleDescription}>(Deutsch für Zuwanderer)</h4>
        <p className={styles.bannerText}>
          Підготуйся до успішного складання іспиту на рівень B1 завдяки нашій
          програмі. Ми допоможемо тобі покращити всі ключові навички: письмо,
          аудіювання, читання та говоріння, щоб ти впевнено склав іспит та досяг
          своїх цілей!
        </p>

        <div className={styles.btnContainer} onClick={() => setClick(true)}>
          <a
            href='https://t.me/ottohans_school_bot?start=6745f14641d917f296009832'
            className={styles.button}
          >
            {button.text}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Banner;
