import React from 'react';
import styles from './book.module.scss';
import classNames from 'classnames';
import Icon from '../../../../elements/icon/Icon';
import Heading from '../../../../elements/heading/Heading';

const Book = () => {
  return (
    <div className={classNames('container', styles.book)}>
      <div className={classNames('wrapper', styles.contentContainer)}>
        <div className={styles.iconContainer}>
          <Icon iconName='otto' />
        </div>
        <div className={styles.secondIconContainer}>
          <Icon iconName='dot-arrow' />
        </div>

        <div className={styles.titleContainer}>
          <Heading name='Склади іспит DTZ разом з Otto Hans!' />
        </div>
        <p className={styles.titleDesc}>
          Цей курс підготує вас до успішного складання всіх частин іспиту{' '}
        </p>

        <a
          href='https://t.me/ottohans_school_bot?start=6745f14641d917f296009832'
          className={styles.button}
        >
          <span>Записатись на курс DTZ B1</span>
        </a>
      </div>
    </div>
  );
};

export default Book;
