import React from 'react';
import styles from './special.module.scss';
import classNames from 'classnames';
import { WaveDown, WaveUp } from '../../../../components/about-us/waves';
import Icon from '../../../../elements/icon/Icon';
import Countdown from '../countdown/Countdown';

const Special = () => {
  return (
    <div className={classNames('container')}>
      <div className={classNames('wrapper', styles.special)}>
        <div className={styles.contentContainer}>
          <div className={styles.card}>
            <div className={styles.iconContainer}>
              <Icon iconName='pencil' />
            </div>
            <h2 className={styles.cardTitle}>
              <span className={styles.blackFriday}>
                Чорна п'ятниця у Otto Hans!
              </span>
              <span className={styles.offer}>
                Запишись на курс DTZ B1 за спеціальною пропозицією до 29.11
                включно
              </span>
            </h2>
            <div className={styles.priceContainers}>
              <div className={styles.actualPriceContainer}>
                <div className={styles.secondIconContainer}>
                  <Icon iconName='lightning' />
                </div>
                <div className={styles.actualPrice}>690грн</div>
                <div className={styles.discount}>
                  Знижка <span>30%</span>
                </div>
              </div>
              <div className={styles.oldPrice}>
                <div className={styles.line}></div>990грн
              </div>
            </div>
            <div className={styles.endingContainer}>
              <div className={styles.actionContainer}>
                <div className={styles.actionText}>
                  До закінчення дії акції:
                </div>
                <div className={styles.countdown}>
                  <Countdown />
                </div>
              </div>
              <a
                href='https://t.me/ottohans_school_bot?start=6745f14641d917f296009832'
                className={styles.button}
              >
                <span>Записатися на курс</span>
                <div className={styles.thirdIconContainer}>
                  <Icon iconName='piggy-bank' />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <WaveDown />
      </div>
    </div>
  );
};

export default Special;
