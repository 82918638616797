// Styles
import styles from './courses.module.scss';
import './coursesSwiper.scss';

import { useState } from 'react';
import Tab from './tab/Tab';
import Heading from '../../elements/heading/Heading';
import classNames from 'classnames';
import { ButtonTwo } from '../../elements/button/Button';

// Swiper modules
import { Navigation, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper SCSS
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/autoplay';

const tabs = {
  ADULTS: 'Дорослі',
  TEENAGERS: 'Діти',
  // PRESCHOOL: 'Дошкільнята',
};

// export const forPreschool = [
//   {
//     title: 'Jana und Dino',
//     duration: '6 місяців (2 заняття на тиждень по 45хв)',
//     // book: 'Книжка - DAF Leicht A1',
//     description: [
//       'Наші найменші відвідувачі - це дітки віком 4-6 років. Для них створена спеціальна програма, яка адаптує малюків до спілкування німецькою з раннього віку. Кофротна ігрова атмосфера, цікаві та розвиваючі ігри.',
//     ],
//     time: '45хв',
//     forOne: '100',
//     priceForEight: '800',
//   },
// ];

export const forTeenagers = [
  {
    title: 'Deutschprofis (offline)',
    duration: 'Школярі - A1, A2, B1',
    bookGerman: 'Miteinander',
    bookEnglish: 'Go Getter',
    description: [
      '2 заняття в тиждень по 1 годині',
      'Від початківця до B1',
      'Спільні інтереси та нове коло спілкування',
      'Тематичні вечори та свята',
      'Тестування та сертифікат в кінці курсу',
    ],
    time: '60хв',
    forOneBig: '185',
    forOneSmall: '225',
    bigGroup: '1480',
    smallGroup: '1800',
    peopleSmall: '3-5',
    peopleBig: '6-8',
  },
  {
    title: 'Kids&teens (online)',
    duration: 'Підлітки - A1, A2, B1, B2, C1',
    bookGerman: 'Miteinander, Mit uns, Aspekte neu für Jugendliche',
    bookEnglish: 'Go Getter',
    description: [
      '2 заняття в тиждень по 1 годині',
      'Від нуля до вступу в університет',
      'Більше, ніж просто вивчення',
      'Кіновечори та мовні подорожі',
    ],
    time: '60хв',
    forOneBig: '200',
    forOneSmall: '250',
    bigGroup: '1600',
    smallGroup: '2000',
    peopleSmall: '3-5',
    peopleBig: '6-8',
  },
];

export const forAdults = [
  {
    title: 'A1',
    duration: '4 місяці (3 заняття на тиждень по 1.5год.)',
    bookGerman: 'Miteinander A1',
    bookEnglish: 'Roadmap',
    description: [
      'дізнатися маршрут/пояснити куди пройти',
      'орієнтуватись в громадському транспорті',
      'зробити замовлення в кафе або ресторані',
      'порозумітись при поселенні в готель',
      'домовлятися про зустрічі',
    ],
    time: '90хв',
    forOneBig: '225',
    forOneSmall: '300',
    bigGroup: '1800',
    smallGroup: '2400',
    peopleSmall: '3-4',
    peopleBig: '5-8',
  },
  {
    title: 'A2',
    duration: '4 місяці (3 заняття на тиждень по 1.5год.)',
    bookGerman: 'Miteinander A2',
    bookEnglish: 'Roadmap',
    description: [
      'висловлювати власну думку',
      'коментувати фільми, телепрограми, книги',
      'писати відгуки у соцмережах',
      'розуміти оголошення у громадських місцях',
      'робити замовлення в кафе чи ресторані',
    ],
    time: '90хв',
    forOneBig: '225',
    forOneSmall: '300',
    bigGroup: '1800',
    smallGroup: '2400',
    peopleSmall: '3-4',
    peopleBig: '5-8',
  },
  {
    title: 'B1',
    duration: '4 місяці (3 заняття на тиждень по 1.5год.)',
    bookGerman: 'Miteinander B1',
    bookEnglish: 'Speak Out',
    description: [
      'орієнтуватися у виборі готелю',
      'написати не лише відгук, а й скаргу',
      'розуміти інформацію про історичні події',
      'відкрити рахунок у банку',
      'підготуватися до співбесіди',
    ],
    time: '90хв',
    forOneBig: '225',
    forOneSmall: '300',
    bigGroup: '1800',
    smallGroup: '2400',
    peopleSmall: '3-4',
    peopleBig: '5-8',
  },
  {
    title: 'B2',
    duration: '6 місяців (3 заняття на тиждень по 1.5год.)',
    bookGerman: 'Vielfalt B2',
    bookEnglish: 'Speak Out',
    description: [
      'спонтанно порозумітися із носієм мови',
      'написати резюме, мотиваційний лист',
      'обмінюватися фаховими знаннями',
      'розпізнавати мовні звороти у піснях, фільмах',
      'дискутувати на тему медицини, страхування',
    ],
    time: '90хв',
    forOneBig: '250',
    forOneSmall: '450',
    bigGroup: '2000',
    smallGroup: '3600',
    peopleSmall: '3-4',
    peopleBig: '5-8',
  },
  {
    title: 'С1',
    duration: '6 місяців (3 заняття на тиждень по 1.5год.)',
    bookGerman: 'Vielfalt C1',
    bookEnglish: 'Speak Out',
    description: [
      'читати наукові статті та фахові тексти',
      'розуміти сенси «між рядків»',
      'донести власну думку чітко та структуровано',
      'оперативно формулювати фрази',
    ],
    time: '90хв',
    forOneBig: '250',
    forOneSmall: '450',
    bigGroup: '2000',
    smallGroup: '3600',
    peopleSmall: '3-4',
    peopleBig: '5-8',
  },
];

const Courses = () => {
  const [tab, setTab] = useState(tabs.ADULTS);
  const [isActive, setIsActive] = useState(false);

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((event) => !event);
  };
  const navList = Object.values(tabs);

  const buttonStyles = {
    backgroundColor: '#FFF108',
    padding: '18px 26px',
    text: 'Записатись',
  };

  return (
    <div id='courses' className={styles.wrapper}>
      {' '}
      <div
        data-aos='fade-right'
        data-aos-duration='500'
        className={classNames('wrapper', styles.heading)}
      >
        <Heading name='Наші курси' />
      </div>
      <div className='blockContainer'>
        <nav className={styles.nav}>
          <ul className={styles.tabs}>
            {navList.map((el, index) => (
              <li>
                <div
                  key={`li-${index}`}
                  onClick={() => setTab(el)}
                  className={classNames(
                    styles.el,
                    tab === el && styles.clicked
                  )}
                >
                  {el}
                </div>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className={classNames(styles.content)}>
        {/* {tab === tabs.PRESCHOOL ? (
          <div className={styles.contentCards}>
            <Tab
              class={styles.preschoolTitle}
              title={forPreschool[0].title}
              duration={forPreschool[0].duration}
              description={
                <ul className={styles.course}>
                  {forPreschool[0].description.map((element, index) => (
                    <li key={`li-${index}`} className={styles.element}>
                      {element}
                    </li>
                  ))}
                </ul>
              }
              time={forPreschool[0].time}
              forOne={forPreschool[0].forOne}
              priceForEight={forPreschool[0].priceForEight}
            />
          </div>
        ) : null} */}
        {tab === tabs.TEENAGERS ? (
          <Swiper
            modules={[Navigation, A11y]}
            spaceBetween={20}
            slidesPerView={'auto'}
            navigation
            className='teenagerSwiper'
          >
            <div className={classNames(styles.slider, styles.contentCards)}>
              {forTeenagers.map((teenager) => (
                <SwiperSlide>
                  <Tab
                    class={styles.teenagerTitle}
                    title={teenager.title}
                    bookGerman={teenager.bookGerman}
                    bookEnglish={teenager.bookEnglish}
                    description={
                      <ul className={styles.course}>
                        {teenager.description.map((item, index) => (
                          <li key={`li-${index}`} className={styles.item}>
                            {item}
                          </li>
                        ))}
                      </ul>
                    }
                    time={teenager.time}
                    forOneBig={teenager.forOneBig}
                    forOneSmall={teenager.forOneSmall}
                    bigGroup={teenager.bigGroup}
                    smallGroup={teenager.smallGroup}
                  />
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        ) : null}

        {tab === tabs.ADULTS ? (
          <Swiper
            modules={[Navigation, A11y]}
            spaceBetween={20}
            slidesPerView={'auto'}
            navigation
            className='coursesSwiper'
          >
            <div className={classNames(styles.slider, styles.contentCards)}>
              {forAdults.map((adult) => (
                <SwiperSlide>
                  <Tab
                    value={adult.title}
                    class={styles.adultsTitle}
                    title={adult.title}
                    bookGerman={adult.bookGerman}
                    bookEnglish={adult.bookEnglish}
                    duration={adult.duration}
                    description={
                      <ul className={styles.course}>
                        {adult.description.map((item, index) => (
                          <li key={`li-${index}`} className={styles.item}>
                            {item}
                          </li>
                        ))}
                      </ul>
                    }
                    time={adult.time}
                    forOneBig={adult.forOneBig}
                    forOneMiddle={adult.forOneMiddle}
                    forOneSmall={adult.forOneSmall}
                    bigGroup={adult.bigGroup}
                    middleGroup={adult.middleGroup}
                    smallGroup={adult.smallGroup}
                  />
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        ) : null}
      </div>
    </div>
  );
};

export default Courses;
