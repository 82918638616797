import styles from './reviews.module.scss';
import Heading from '../../elements/heading/Heading';
import Icon from './../../elements/icon/Icon';
import './absolute.scss';
import classNames from 'classnames';

import commentOne from './../../assets/img/comments/comment-1.png';
import commentTwo from './../../assets/img/comments/comment-2.png';
import commentThree from './../../assets/img/comments/comment-3.png';
import commentFour from './../../assets/img/comments/comment-4.png';
import commentFive from './../../assets/img/comments/comment-5.png';
import commentSix from './../../assets/img/comments/comment-6.png';
import commentSeven from './../../assets/img/comments/comment-7.png';
import commentEight from './../../assets/img/comments/comment-8.png';
import commentNine from './../../assets/img/comments/comment-9.png';

const Reviews = () => {
  return (
    <div id='reviews' className={classNames('wrapper', styles.wrapper)}>
      <div
        data-aos='fade-right'
        data-aos-duration='1500'
        className={styles.heading}
      >
        <Heading name='Що кажуть наші учні' />
      </div>
      <div className={styles.commentsContainer}>
        <div className={classNames(styles.icon, styles.smileyOne)}>
          <div>
            <Icon iconName='smiley-1' />
          </div>
        </div>
        <div className={classNames(styles.icon, styles.smileyTwo)}>
          <div>
            <Icon iconName='smiley-2' />
          </div>
        </div>
        <div className={classNames(styles.icon, styles.smileyThree)}>
          <div>
            <Icon iconName='smiley-3' />
          </div>
        </div>
        <div className={classNames(styles.icon, styles.heart)}>
          <div>
            <Icon iconName='heart' />
          </div>
        </div>
        <div className={classNames(styles.icon, styles.ruler)}>
          <div>
            <Icon iconName='ruler-2' />
          </div>
        </div>
        <div className={classNames(styles.comment, styles.commentOne)}>
          <div data-aos='flip-right' data-aos-duration='1500'>
            <img src={commentOne} alt='Comment one' />
          </div>
        </div>
        <div className={classNames(styles.comment, styles.commentTwo)}>
          <div data-aos='flip-right' data-aos-duration='1500'>
            <img src={commentTwo} alt='Comment two' />
          </div>
        </div>
        <div className={classNames(styles.comment, styles.commentThree)}>
          <div data-aos='flip-right' data-aos-duration='1500'>
            <img src={commentThree} alt='Comment three' />
          </div>
        </div>
        <div className={classNames(styles.comment, styles.commentFour)}>
          <div data-aos='flip-left' data-aos-duration='1500'>
            <img src={commentFour} alt='Comment four' />
          </div>
        </div>
        <div className={classNames(styles.comment, styles.commentFive)}>
          <div data-aos='flip-left' data-aos-duration='1500'>
            <img src={commentFive} alt='Comment five' />
          </div>
        </div>
        <div className={classNames(styles.comment, styles.commentSix)}>
          <div data-aos='flip-down' data-aos-duration='1500'>
            <img src={commentSix} alt='Comment six' />
          </div>
        </div>
        <div className={classNames(styles.comment, styles.commentSeven)}>
          <div data-aos='flip-down' data-aos-duration='1500'>
            <img src={commentSeven} alt='Comment seven' />
          </div>
        </div>
        <div className={classNames(styles.comment, styles.commentEight)}>
          <div data-aos='flip-up' data-aos-duration='1500'>
            <img src={commentEight} alt='Comment eight' />
          </div>
        </div>
        <div className={classNames(styles.comment, styles.commentNine)}>
          <div data-aos='flip-up' data-aos-duration='1500'>
            <img src={commentNine} alt='Comment nine' />
          </div>
        </div>
      </div>
    </div>
  );
};

// // ! Random picture
// const images = [one, two, three, four, five];

// const Review = () => {
//   const [counter, setCounter] = useState(images[0]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCounter(() => [Math.floor(Math.random() * images.length)]);
//     }, 2000);

//     return () => clearInterval(interval);
//   }, []);

//   // ! Random position
//   const ChipStyles = useRef({
//     top: `${Math.floor((Math.random()) * 500)}px`,
//     left: `${Math.floor((Math.random()) * 500)}px`,
//     transform: 'translate(50%, -50%)',
//   });

//   const randomImage = images[Math.floor(Math.random() * images.length)];
//   const randomImageOne = images[Math.floor(Math.random() * images.length)];
//   const randomImageTwo = images[Math.floor(Math.random() * images.length)];
//   const randomImageThree = images[Math.floor(Math.random() * images.length)];
//   return (
//     <div className={classNames('wrapper', styles.wrapper)}>
//       <div style={ChipStyles.current} className={styles.imageContainer}>
//         <img src={randomImage} alt='' />
//       </div>
//       <div className={styles.imageContainer}>
//         <img src={randomImageOne} alt='' />
//       </div>
//       <div className={styles.imageContainer}>
//         <img src={randomImageTwo} alt='' />
//       </div>
//       <div className={styles.imageContainer}>
//         <img src={randomImageThree} alt='' />
//       </div>
//     </div>
//   );
// };

export default Reviews;
