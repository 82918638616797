import React, { useState } from 'react';
import styles from './course-program.module.scss';
import classNames from 'classnames';
import Heading from '../../../../elements/heading/Heading';

const CourseProgram = () => {
  const [active, setActive] = useState('');

  const data = [
    {
      question: 'Урок 1: Hörverstehen',
      answer:
        'Даний урок продуманий так, щоб виконавши ряд тренуввальних вправ, ти з легкістю зміг/змогла виконати основні завдання, які зустрінуться тобі на іспиті. Це ряд лексичних, а також граматичних вправ, прослуховування різних видів аудіо, які адаптовані до ситуацій схожих на іспиті.',
    },
    {
      question: 'Урок 2: Hörverstehen. Modellsatz',
      answer:
        'У цьому розділі ти знайдеш 4 частини аудіювання як симуляції екзамену, з автоматичною перевіркою завдань.',
    },
    {
      question: 'Урок 3: Leseverstehen',
      answer:
        'Ми зібрали всі необхідні лексичні вправи, які протренують твоє читання до того рівня, щоб це стало можливим з легкістю скласти цю частину іспиту мінімум на 80%. Найтиповіші теми, які трапляються на екзамені, а також автоматична перевірка твоїх тестів.',
    },
    {
      question: 'Урок 4: Leseverstehen. Modellsatz',
      answer:
        'На цьому уроці ти будеш практикувати розуміння прочитаного на рівні B1, використовуючи модельний тест, схожий на той, що використовується на реальному іспиті. Ти ознайомишся з типовими завданнями на розуміння тексту, навчишся виділяти ключову інформацію, ідентифікувати основну ідею та розрізняти важливі деталі. У даному уроці ти знайдеш 5 частин читання.',
    },
    {
      question: 'Урок 5: Schreiben',
      answer:
        'Наступні три уроки присвячені напевно найважчій частині іспиту - це письмо. Тому ми виділили на нього достатньо матеріалів, які допоможуть тобі систематизувати ваші знання, вивчити і закріпити основні фрази, які можуть тобі стати при нагоді на екзамені, а також головне - це структура листа чи електронного листа. Три уроки письма: вправи+поради і приклади+симуляція.',
    },
    {
      question: 'Урок 6: Schreiben',
      answer:
        'Наступні три уроки присвячені напевно найважчій частині іспиту - це письмо. Тому ми виділили на нього достатньо матеріалів, які допоможуть тобі систематизувати ваші знання, вивчити і закріпити основні фрази, які можуть тобі стати при нагоді на екзамені, а також головне - це структура листа чи електронного листа. Три уроки письма: вправи+поради і приклади+симуляція.',
    },
    {
      question: 'Урок 7: Schreiben',
      answer:
        'Наступні три уроки присвячені напевно найважчій частині іспиту - це письмо. Тому ми виділили на нього достатньо матеріалів, які допоможуть тобі систематизувати ваші знання, вивчити і закріпити основні фрази, які можуть тобі стати при нагоді на екзамені, а також головне - це структура листа чи електронного листа. Три уроки письма: вправи+поради і приклади+симуляція.',
    },
    {
      question: 'Урок 8: Sprechen. Üben',
      answer:
        'Останнім завершальним уроком даного курсу є підготовка до говоріння. Здавалося б, як можна його протренувати онлайн? Дуже просто. У даному блоці ти отримуєш ряд вправ, які допоможуть лексично бути готовим до представлення себе, опису картики, а також спільному спонтанному діалогу на запропоновану тему. Додатково ти отримуєш приклад короткої розповіді про себе та відео з описом картинки на реальному іспиті.',
    },
  ];
  return (
    <div className={classNames('wrapper', styles.courseProgram)}>
      <div className={styles.titleContainer}>
        <Heading name='Програма курсу' />
      </div>
      <div className={styles.accordionContainer}>
        {data.map((item, index) => (
          <div>
            <div
              key={index}
              className={classNames(
                styles.item,
                active === item.question ? styles.open : ''
              )}
              onClick={() =>
                item.question !== active
                  ? setActive(item.question)
                  : setActive('')
              }
            >
              <h5 className={styles.smallHeading}>{item.question}</h5>

              <p
                className={classNames(
                  styles.answer,
                  active === item.question ? styles.slideDown : ''
                )}
              >
                {item.answer}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.dotYellow}></div>
      <div className={styles.dotRed}></div>
    </div>
  );
};

export default CourseProgram;
