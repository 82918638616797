import React, { useState, useEffect } from 'react';
import styles from './countdown.module.scss';

const Countdown = () => {
  // Цільова дата
  const targetDate = new Date('2024-11-29T00:00:00').getTime();

  // Стан для збереження залишку часу в секундах
  const [timeRemaining, setTimeRemaining] = useState(
    Math.max(0, Math.floor((targetDate - Date.now()) / 1000))
  );

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const currentTime = Math.floor((targetDate - Date.now()) / 1000);
      if (currentTime <= 0) {
        clearInterval(timerInterval);
        setTimeRemaining(0);
      } else {
        setTimeRemaining(currentTime);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [targetDate]);

  // Функція для форматування чисел
  const formatTime = (value) => value.toString().padStart(2, '0');

  // Розрахунок днів, годин, хвилин і секунд
  const days = Math.floor(timeRemaining / 86400);
  const hours = Math.floor((timeRemaining % 86400) / 3600);
  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = timeRemaining % 60;

  return (
    <div className={styles.countdown}>
      <div className={styles.cell}>
        {formatTime(days)} <div className={styles.cellDesc}>дні</div>
      </div>
      <div className={styles.cell}>
        {formatTime(hours)} <div className={styles.cellDesc}>години</div>
      </div>
      <div className={styles.cell}>
        {formatTime(minutes)} <div className={styles.cellDesc}>хвилини</div>
      </div>
      <div className={styles.cell}>
        {formatTime(seconds)} <div className={styles.cellDesc}>секунди</div>
      </div>
    </div>
  );
};

export default Countdown;
